<template>
  <div class="card-carousel" @mouseover="stopTimer" @mouseleave="restartTimer">
    <div class="progressbar" v-if="autoSlideInterval && showProgressBar">
      <div :style="{ width: progressBar + '%' }"></div>
    </div>
    <div class="card-img">
      <swiper :options="swiperOption" ref="mySwiper" @slide-change-transition-end="handleSwiperSlide">

        <swiper-slide v-for="(item,index) in setImages" :key="index">
          <!-- <img :src="item.big" alt="" /> -->
          <div style="width: 100%; height:0px;padding-bottom: 100%;overflow:hidden;position: relative;">
            <img
            :src="item.big"
            style="width: 100%; height:auto;left:0;top: 50%;transform: translateY(-50%);border:0px;position: absolute;"
            >
          </div>
        </swiper-slide>
        <!-- 左箭头 -->
        <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
        <!-- 右箭头 -->
        <!-- <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
      <!-- <div class="actions">
        <span @click="prevImage" class="prev"> &#8249; </span>
        <span @click="nextImage" class="next"> &#8250; </span>
      </div> -->
    </div>
    <div
      :class="
        productInfo.oversold == 1 && productInfo.totalCount == 0
          ? 'soldOut'
          : 'allowNone'
      "
    >
      SOLD OUT
    </div>
    <div class="thumbnails">
      <div
        v-for="(image, index) in setImages"
        :key="image.id"
        :class="['thumbnail-image', activeImage == index ? 'active' : '']"
        @click="clickSmallImage(index)"
      >
        <img :src="image.thumb" />
      </div>
    </div>
  </div>
</template>

<script>
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'Carousel',
  components:{
    swiper,
    swiperSlide
  },
  props: [
    'startingImage',
    'images',
    'autoSlideInterval',
    'showProgressBar',
    'productInfo',
    'attrName',
  ],
  data() {
    return {
      //Index of the active image
      activeImage: 0,
      //Hold the timeout, so we can clear it when it is needed
      autoSlideTimeout: null,
      //If the timer is stopped e.g. when hovering over the carousel
      stopSlider: false,
      //Hold the time left until changing to the next image
      timeLeft: 0,
      //Hold the interval so we can clear it when needed
      timerInterval: null,
      //Every 10ms decrease the timeLeft
      countdownInterval: 10,
      swiperOption: {
        loop: true,
        notNextTick: true, // notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        slidesPerView: 1, //数量
        direction: "horizontal", //设置水平还是垂直vertical
        autoplay:{
          delay: 10000, //自动切换的时间间隔，单位ms
          stopOnLastSlide: true, //当切换到最后一个slide时停止自动切换
          disableOnInteraction: true, //用户操作swiper之后，是否禁止autoplay
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      setImages: [],
    }
  },
  watch: {
    images: {
      handler(val) {
        console.log('warch..........', this.attrName)
        if (this.attrName) { // 仅触发当前页面的SKU
          this.resetImage()
          this.$refs.mySwiper.swiper.slideTo(0, 1000, false)
        } else { // 切换商品
          // console.log(this.productInfo)
          // console.log(val)
          this.resetImage()
          this.setImages = []
          setTimeout(() => {
            this.setImages = val
            this.$refs.mySwiper.swiper.slideTo(this.activeImage, 1000, false);
          }, 100)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    // currentImage gets called whenever activeImage changes
    // and is the reason why we don't have to worry about the
    // big image getting updated
    currentImage() {
      this.timeLeft = this.autoSlideInterval
      return this.setImages[this.activeImage].big
    },
    progressBar() {
      //Calculate the width of the progressbar
      return 100 - (this.timeLeft / this.autoSlideInterval) * 100
    },
  },
  created() {
    //Check if startingImage prop was given and if the index is inside the images array bounds
    if (
      this.startingImage &&
      this.startingImage >= 0 &&
      this.startingImage < this.images.length
    ) {
      this.activeImage = this.startingImage
    }

    //Check if autoSlideInterval prop was given and if it is a positive number
    if (
      this.autoSlideInterval &&
      this.autoSlideInterval > this.countdownInterval
    ) {
      //Start the timer to go to the next image
      this.startTimer(this.autoSlideInterval)
      this.timeLeft = this.autoSlideInterval
      //Start countdown to show the progressbar
      this.startCountdown()
    }
  },
  methods: {
    resetImage() {
      this.activeImage = 0
      this.autoSlideTimeout = null
      this.stopSlider = false
      this.timeLeft = 0
      this.timerInterval = null
      this.countdownInterval = 10
    },
    handleSwiperSlide() {
      var active = this.$refs.mySwiper.swiper.activeIndex
      if (active >= this.setImages.length) {
        active = 0
      }
      this.activateImage(active)
    },
    // Go forward on the setImages array
    // or go at the first image if you can't go forward
    nextImage() {
      var active = this.activeImage + 1
      if (active >= this.setImages.length) {
        active = 0
      }
      this.activateImage(active)
      console.log(active)
      this.$refs.mySwiper.swiper.slideTo(active, 1000, false);
    },
    // Go backwards on the setImages array
    // or go at the last image
    prevImage() {
      var active = this.activeImage - 1
      if (active < 0) {
        active = this.setImages.length - 1
      }
      this.activateImage(active)
      console.log(active)
      this.$refs.mySwiper.swiper.slideTo(active, 1000, false); // slideTo是从1开始算的
    },
    clickSmallImage(imageIndex) {
      this.activateImage(imageIndex)
      this.$refs.mySwiper.swiper.slideTo(imageIndex, 1000, false);
    },
    activateImage(imageIndex) {
      this.activeImage = imageIndex
    },
    //Wait until 'interval' and go to the next image;
    startTimer(interval) {
      if (interval && interval > 0 && !this.stopSlider) {
        var self = this
        clearTimeout(this.autoSlideTimeout)
        this.autoSlideTimeout = setTimeout(function () {
          self.nextImage()
          self.startTimer(self.autoSlideInterval)
        }, interval)
      }
    },
    //Stop the timer when hovering over the carousel
    stopTimer() {
      clearTimeout(this.autoSlideTimeout)
      this.stopSlider = true
      clearInterval(this.timerInterval)
    },
    //Restart the timer(with 'timeLeft') when leaving from the carousel
    restartTimer() {
      this.stopSlider = false
      clearInterval(this.timerInterval)
      this.startCountdown()
      this.startTimer(this.timeLeft)
    },
    //Start countdown from 'autoSlideInterval' to 0
    startCountdown() {
      if (!this.showProgressBar) return
      var self = this
      this.timerInterval = setInterval(function () {
        self.timeLeft -= self.countdownInterval
        if (self.timeLeft <= 0) {
          self.timeLeft = self.autoSlideInterval
        }
      }, this.countdownInterval)
    },
  },
}
</script>


<style scoped>
.card-carousel {
  user-select: none;
  position: relative;
}

.progressbar {
  display: block;
  width: 100%;
  height: 5px;
  position: absolute;
  background-color: rgba(221, 221, 221, 0.25);
  z-index: 1;
}

.progressbar > div {
  background-color: rgba(255, 255, 255, 0.52);
  height: 100%;
}

.thumbnails {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.thumbnail-image {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  width: 20%;
}

.thumbnail-image > img {
  width: 100%;
  height: auto;
  transition: all 250ms;
}

.thumbnail-image:hover > img,
.thumbnail-image.active > img {
  opacity: 0.6;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.5);
}

.card-img {
  position: relative;
  margin-bottom: 15px;
}

.card-img > img {
  /*display: block;*/
  margin: 0 auto;
  object-fit: contain;
}

/* .actions {
  font-size: 1.5em;
  height: 40px;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #585858;
  z-index: 2;
}

.actions > span {
  cursor: pointer;
  transition: all 250ms;
  font-size: 45px;
}

.actions > span.prev {
  margin-left: 5px;
}

.actions > span.next {
  margin-right: 5px;
}

.actions > span:hover {
  color: #eee;
} */
/* Customize the navigation buttons */
.swiper-button-prev, .swiper-button-next {
  color: #fff;
  height: auto;
  font-size: 28px;
  /* Position the buttons */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  /* background: rgba(0, 0, 0, 0.5); */
  padding: 10px;
  /* Rounded buttons */
  border-radius: 10px;
}
.swiper-button-prev {
  left: 20px;
}
.swiper-button-next {
  right: 20px;
}
.soldOut {
  position: absolute;
  font-size: 1rem;
  color: rgba(249, 40, 8, 0.88);
  top: 0.5rem;
  right: 0.5rem;
}
.allowNone {
  display: none;
}
</style>
