// import CryptoJS from 'cryptojs'
import CryptoJS from "crypto-js";
//import CryptoJS from '@/assets/crypto-js.min'
export function isLogin() {
  if (!sessionStorage.getItem('satoken') && !localStorage.getItem('satoken')) {
    return false;
  }
  return true;
}
export function calcPriceOff(curPrice, originalPrice, decimal = 2) {
  return (curPrice / originalPrice).toFixed(decimal) * 100
}

export function AES() {
  const uuid32 = "00010203-04050607-08090A0B-0C0D0E0F".toString();
  const param = Array.from(uuid32.replaceAll("-", "").trim()).reduce((prev, curr, idx) => {
    (0 === idx % 2 ? prev.key : prev.iv).unshift(curr);
    return prev;
  },
    { key: [], iv: [] });
  function sha1prng(key, length) {
    let hash = CryptoJS.SHA1(key);
    let result = CryptoJS.SHA1(hash).toString();
    return CryptoJS.enc.Hex.parse(result.substring(0, length));
  }
  const key = sha1prng(param.key.join(''), 32);
  const iv = CryptoJS.enc.Utf8.parse(param.iv.join(''));
  const cfg = { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 };

  function Encrypt(text) {
    return CryptoJS.AES.encrypt(text, key, cfg).toString();
  }

  function Decrypt(text) {
    let src = window.decodeURIComponent(text)
    return CryptoJS.AES.decrypt(src, key, cfg).toString(CryptoJS.enc.Utf8);
  }

  return {
    Encrypt,
    Decrypt
  }
};
