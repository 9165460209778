<template>
<div style="padding:0 10px">
  <el-row :gutter="40">
    <el-col :span="24" :sm="12" >
      <carousel
        v-if="images.length > 0"
        :starting-image="0"
        :images="images"
        :attr-name="attrName"
        :productInfo="productInfo"
      ></carousel>
      <div v-else>
        <img src="@/assets/icon/No-Photo-Available.webp" width="80%" alt="" />
      </div>
    </el-col>
    <el-col :span="24" :sm="12" >
      <div class="product-detail" >
        <div
          class="product-detail__header"
          @click="handleToProductDetail(productInfo)"
        >
          <h2>{{ productInfo.name }}</h2>
        </div>
        <div class="product-detail__desc">
          <span v-if="productInfo.intro" v-html="productInfo.intro.replace(/\n/g, '<br>')"></span>
        </div>
        <div class="product-detail__price">
          <span class="cur-price" style="color: #de6d5c;">${{ showSellingPrice }}</span>
          <span class="original-price" v-if="productInfo.originalPrice"
            >${{ productInfo.originalPrice }}</span
          >
        </div>
        <div class="product-detail__comment">
          <star-rating
            v-model="productInfo.score"
            :read-only="true"
            :increment="0.5"
            :show-rating="false"
            :star-size="25"
          ></star-rating>
          <p style="margin-left: 8px">
            {{ productInfo.reviewsNum ? productInfo.reviewsNum : 'No' }} reviews
          </p>
        </div>
        <div v-if="productInfo.attrs && productInfo.attrs.length > 0">
          <div
            v-for="attrItem in productInfo.attrs"
            :key="attrItem.attrCode"
            :class="
              attrItem.attrCode === 'Color'
                ? 'product-detail__color-pick'
                : 'product-detail__size-pick'
            "
          >
            <p class="aaa">{{ attrItem.attrCode }}:</p>
            <div
              :class="
                attrItem.attrCode === 'Color'
                  ? 'product-detail__color-switch'
                  : 'product-detail__size-switch'
              "
            >
              <span
                :class="
                  attrItem.attrCode === 'Color' ? 'color-item' : 'size-item'
                "
                v-for="(detailItem, index) in attrItem.details"
                :key="detailItem.id"
              >
                <input
                  type="radio"
                  :name="attrItem.attrCode"
                  :id="detailItem.id"
                  @click="
                    handleAttrClick(attrItem.attrCode, detailItem.detailCode)
                  "
                />
                <label
                  :for="detailItem.id"
                  v-if="attrItem.attrCode === 'Color'"
                  :class="['color-circle', detailItem.detailCode]"
                  :style="{ 'background-color': detailItem.detailCode }"
                  >{{ detailItem.detailName }}</label
                >
                <el-image
                  v-else-if="detailItem.detailImg"
                  :for="detailItem.id"
                  @click="
                    handleAttrClick(
                      attrItem.attrCode,
                      detailItem.detailCode,
                      index
                    )
                  "
                  :class="[
                    'image-circle',
                    activeImage == index ? 'active' : '',
                    'imageSize',
                  ]"
                  :style="{ 'background-color': detailItem.detailCode }"
                  :src="detailItem.detailImg"
                  fit="fill"
                ></el-image>
                <label v-else :for="detailItem.id" class="size-check">{{
                  detailItem.detailName
                }}</label>
              </span>
            </div>
          </div>
        </div>
        <div class="product-detail__quantity-pick">
          <p style="margin-bottom: 12px">Quantity:</p>
          <el-input-number
            v-model="productCount"
            :min="1"
            :max="10"
          ></el-input-number>
        </div>
        <div class="product-detail__actions">
          <button
            class="product-detail__add-to-cart"
            @click="addToCart"
            :disabled="!checkedAllAttrs"
          >
            Add to cart
          </button>
          <button
            class="product-detail__buy"
            :disabled="!checkedAllAttrs"
            @click="quickOrder"
          >
            Buy it now
          </button>
        </div>
        <div class="article-page__share">
          <p class="article-page__share-text">Share this:</p>
          <div class="share-buttons">
            <a
              href="javascript:void(0)"
              @click="twitterShare"
              class="share-buttons__twitter"
            >
              <svg
                class="icon-twitter"
                aria-hidden="true"
                focusable="false"
                role="presentation"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M20 3.895a8.474 8.474 0 01-2.034 2.084c0 .055.007.142.021.26.014.118.02.205.02.261 0 1.083-.162 2.167-.487 3.25a11.413 11.413 0 01-1.483 3.105c-.663.986-1.45 1.86-2.362 2.624-.912.765-2.006 1.376-3.284 1.834-1.277.458-2.65.687-4.12.687-2.288 0-4.379-.604-6.271-1.813.396.028.72.042.974.042 1.907 0 3.61-.57 5.107-1.709a3.991 3.991 0 01-2.384-.801 4.139 4.139 0 01-1.472-2.01c.24.056.501.083.784.083.381 0 .741-.055 1.08-.166a4.066 4.066 0 01-2.352-1.396 3.825 3.825 0 01-.932-2.542v-.042c.536.278 1.15.431 1.843.458a3.956 3.956 0 01-1.313-1.427A3.904 3.904 0 01.847 4.77c0-.695.177-1.375.53-2.042a11.255 11.255 0 003.739 2.99c1.462.73 3.047 1.135 4.756 1.219a3.395 3.395 0 01-.15-.918c0-1.11.403-2.059 1.208-2.843C11.735 2.392 12.716 2 13.875 2c.565 0 1.108.111 1.631.333.523.223.968.535 1.335.938.932-.18 1.801-.507 2.606-.98-.297.945-.897 1.688-1.801 2.23a8.706 8.706 0 002.352-.625L20 3.895z"
                ></path>
              </svg>
              <span class="share-buttons__share-text">Twitter</span>
            </a>
            <a href="" class="share-buttons__facebook" @click="facebookShare()">
              <svg
                class="icon-facebook"
                aria-hidden="true"
                focusable="false"
                role="presentation"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 9 20"
              >
                <path
                  fill="currentColor"
                  d="M8.3 6.309H5.533V4.43c0-.691.494-.89.79-.89H8.3V.38H5.533C2.47.38 1.78 2.751 1.78 4.234v2.075H0v3.26h1.779v9.19h3.754v-9.19h2.47L8.3 6.31z"
                ></path>
              </svg>
              <span class="share-buttons__share-text">facebook</span>
            </a>
            <!-- <a href="" class="share-buttons__pinterest">
              <svg
                class="icon-pinterest"
                aria-hidden="true"
                focusable="false"
                role="presentation"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M9.958.811c1.268 0 2.48.248 3.635.744a9.448 9.448 0 012.988 2 9.448 9.448 0 012 2.988 9.115 9.115 0 01.744 3.635 9.175 9.175 0 01-1.256 4.696 9.291 9.291 0 01-3.415 3.415 9.176 9.176 0 01-4.696 1.256 9.548 9.548 0 01-2.66-.366c.472-.765.79-1.44.952-2.025l.659-2.561c.163.309.464.581.903.817.44.236.902.354 1.39.354.976 0 1.85-.28 2.622-.842.772-.561 1.37-1.33 1.793-2.305.423-.975.634-2.073.634-3.293 0-1.447-.557-2.704-1.671-3.769-1.114-1.065-2.533-1.598-4.257-1.598-1.057 0-2.025.179-2.903.537-.878.358-1.585.826-2.122 1.403a6.415 6.415 0 00-1.232 1.878 5.2 5.2 0 00-.427 2.037c0 .845.159 1.585.476 2.22a2.703 2.703 0 001.427 1.342c.114.049.212.053.293.012.081-.04.138-.118.17-.232.115-.407.18-.659.196-.756.065-.179.025-.35-.122-.512-.423-.471-.634-1.081-.634-1.83 0-1.236.427-2.297 1.28-3.183.855-.886 1.973-1.329 3.355-1.329 1.22 0 2.171.333 2.854 1 .683.667 1.024 1.537 1.025 2.61 0 .895-.122 1.72-.366 2.476s-.594 1.362-1.05 1.817c-.454.455-.975.683-1.56.683-.488 0-.886-.179-1.195-.537-.309-.358-.406-.781-.293-1.269.065-.228.15-.52.256-.878.105-.357.195-.662.268-.915.073-.253.142-.525.207-.817a3.55 3.55 0 00.098-.732c0-.407-.106-.74-.317-1-.211-.26-.52-.39-.927-.39-.504 0-.927.232-1.27.695-.341.463-.512 1.045-.511 1.744 0 .26.02.512.06.756.042.244.086.423.135.537l.073.171c-.667 2.895-1.073 4.594-1.22 5.098-.13.618-.179 1.342-.146 2.171a9.417 9.417 0 01-4.062-3.44c-1.033-1.545-1.55-3.261-1.55-5.147 0-2.586.916-4.794 2.745-6.623C5.164 1.724 7.37.81 9.957.81v.001z"
                ></path>
              </svg>
              <span class="share-buttons__share-text">Pin it</span>
            </a> -->
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</div>
</template>
<script>
import service from '@/utils/request'
import Carousel from '@/components/Carousel'
import StarRating from 'vue-star-rating'
export default {
  name: 'ProductOrder',
  components: { Carousel, StarRating },
  props: {
    productInfo: {
      type: Object,
      default: () => {},
    },
    showOff: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeImage: null,
      productCount: 1,
      images: [],
      checkedAttr: {},
      showSellingPrice: '',
      attrName: '',
      flagTag: false,
      // id:'',
      title:''
    }
  },
  computed: {
    checkedAllAttrs() {
      console.log(this.productInfo)
      console.log(this.checkedAttr)
      if (
        JSON.stringify(this.checkedAttr) !== '{}' &&
        Object.keys(this.checkedAttr).length === this.productInfo.attrs.length
      ) {
        let sizeColor = JSON.stringify(this.checkedAttr)
          .replace(/{/g, '')
          .replace(/}/g, '')
          .replace(/"/g, '')
        sizeColor = sizeColor.split(',').sort().join()
        const val = this.productInfo.skus.find((el) => el.value == sizeColor)
        console.log(val.totalCount)
        if (val.enable == '1') {
          // 不可出售
          return false
        } else if (this.productInfo.oversold === '1' && val.totalCount === 0) {
          return false
        }
      }
      console.log(this.checkedAttr)
      if (
        this.productInfo &&
        this.productInfo.oversold === '1' &&
        this.productInfo.totalCount === 0
      ) {
        return false
      }
      if (
        this.productInfo &&
        (!this.productInfo.attrs || this.productInfo.attrs.length === 0)
      ) {
        return true
      }
      return (
        Object.keys(this.checkedAttr).length === this.productInfo.attrs.length
      )
    },
  },
  watch: {
    productInfo(val) {
      this.attrName = ''
      if (val && val.carouselImgs) {
        // console.log('productInfo changed', val)
        this.productCount = 1
        this.checkedAttr = {}
        this.showSellingPrice = val.sellingPrice
        let imageList = val.carouselImgs.split(';')
        this.images = []
        imageList.forEach((item, index) => {
          let appendix = item.lastIndexOf('.')
          let thumb = item.substring(0, appendix) + '_thumbnail.jpg'
          this.images.push({
            id: index,
            big: item,
            thumb,
          })
        })
        console.log( this.images)
      }
    },

  },
  methods: {
    handleAttrClick(attrName, detailCode, index) {
      console.log(attrName)
      console.log(detailCode)
      console.log(this.productInfo)
      this.attrName = attrName
      if (index >= 0) {
        this.activeImage = index
      }
      this.$set(this.checkedAttr, attrName, detailCode)
      this.getSkuName(attrName, index)
    },
    getSkuName(attrName, index) {
      // if (!this.checkedAllAttrs) return
      const sortedAttrs = Object.keys(this.checkedAttr).sort()
      const skuNameArr = []
      sortedAttrs.forEach((item) => {
        skuNameArr.push(item + ':' + this.checkedAttr[item])
      })
      const skuValue = skuNameArr.join(',')
      const matchedSku = this.productInfo.skus.find(
        (item) => item.value === skuValue
      )

      if (
        matchedSku &&
        matchedSku.img &&
        this.images &&
        this.images.length > 0
      ) {
        console.log(this.productInfo)
        // this.images[0].big = matchedSku.img
      } else {
        console.log(456)
        // this.images[0].big = this.productInfo.carouselImgs.split(';')[0]
      }
      console.log(index)
      if (index >= 0) {
        console.log(this.productInfo.attrs)
        const attrs = this.productInfo.attrs
        const checkVal = attrs.find((el) => el.attrCode == attrName).details
        console.log(checkVal)
        this.flagTag = true
        this.$set(
          this.images[0],
          'big',
          checkVal[index].detailImg.replace('_thumbnail', '')
        )
        // this.images[0].big = checkVal[index].detailImg.replace('_thumbnail', '')
        console.log(this.images[0].big)
      }
      console.log(this.images)
      if (matchedSku && matchedSku.price) {
        this.showSellingPrice = matchedSku.price
      } else {
        this.showSellingPrice = this.productInfo.sellingPrice
      }
      this.sku = matchedSku
    },
    addToCart() {
      if (!this.productCount) {
        this.$notify({
          title: 'Tip',
          message: 'Quantity must greater than 0!',
          type: 'warning',
        })
        return
      }
      if (this.productInfo.attrs) {
        for (let attrItem of this.productInfo.attrs) {
          if (!this.checkedAttr[attrItem.attrCode]) {
            this.$notify({
              title: 'Tip',
              message: `You haven't choose ${attrItem.attrCode}!`,
              type: 'warning',
            })
            return
          }
        }
      }
      let attrList = []
      for (let [key, value] of Object.entries(this.checkedAttr)) {
        attrList.push({ attrCode: key, attrValue: value })
      }
      service({
        url: `/car/add`,
        method: 'post',
        data: {
          productId: this.productInfo.id,
          productCount: this.productCount,
          attrList,
        },
      }).then((res) => {
        if (res.data?.am) {
          localStorage.setItem('am', res.data.am)
        }
        this.$emit('addToCartSuccess')
        this.$store.dispatch('cart/setHasNewTip', true)
      })
    },
    quickOrder() {
      if (!this.productCount) {
        this.$notify({
          title: 'Tip',
          message: 'Quantity must greater than 0!',
          type: 'warning',
        })
        return
      }
      if (this.productInfo.attrs) {
        for (let attrItem of this.productInfo.attrs) {
          if (!this.checkedAttr[attrItem.attrCode]) {
            this.$notify({
              title: 'Tip',
              message: `You haven't choose ${attrItem.attrCode}!`,
              type: 'warning',
            })
            return
          }
        }
      }
      // console.log('showSellingPrice=', this.showSellingPrice)
      // console.log('productCount=', this.productCount)
      // return
      console.log({
        productId: this.productInfo.id,
        productCount: this.productCount,
        sellingPrice: this.showSellingPrice,
        checkedAttr: JSON.stringify(this.checkedAttr),
      })
      this.$router.push({
        name: 'Checkout',
        query: {
          productId: this.productInfo.id,
          productCount: this.productCount,
          sellingPrice: this.showSellingPrice,
          checkedAttr: JSON.stringify(this.checkedAttr),
          imagesFlag: this.flagTag ? this.images[0].big : '',
        },
        params: {
          productId: this.productInfo.id,
          skuCode: this.sku?.skuCode,
          checkedAttr: this.checkedAttr,
          productCount: this.productCount,
          sellingPrice: this.showSellingPrice,
        },
      })
    },
    handleToProductDetail(data) {
      if ('ProductDetail' !== this.$route.name) {
        const { id, name } = data
        this.$router.push({
          path: '/product-detail',
          query: {
            type: 'collection',
            productId: id,
            productName: name,
          },
        })
      }
    },
    twitterShare() {
      let id = this.productInfo.id;
      let t = this.productInfo.name;
      let url = this.shareProductUrl + "/" + id
      window.open('http://twitter.com/share?url=' + encodeURIComponent(url) + '&text=' + encodeURIComponent(t), '', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
    },
    facebookShare() {
      let id = this.productInfo.id;
      let t = this.productInfo.name;
      let url = this.shareProductUrl + "/" + id;
      window.open("http://www.facebook.com/sharer.php?u="+ encodeURIComponent(url) + "&t="+ encodeURIComponent(t), "sharer","toolbar=0,status=0,width=626,height=436");
    },
  },
}
</script>
<style scoped>
@media screen and (max-width: 576px){
  .cur-price{
    font-size:1.8rem
  }
}
.product-detail {
  text-align: left;
}
.product-detail__header {
  margin: 1rem 0 0;
  cursor: pointer;
}
.product-detail__desc {
  margin-top: 1rem;
}
.product-detail__price,
.product-detail__comment,
.product-detail__color-pick,
.product-detail__size-pick,
.product-detail__quantity-pick {
  margin-top: 1.125rem;
}
.product-detail__price {
  font-size: 1.125rem;
}
.original-price {
  text-decoration: line-through;
  color: rgb(145 149 156);
  margin-left: 10px;
}
@media screen and (min-width: 576px) {
  .product-detail__price {
    font-size: 1.5rem;
  }
}

.product-detail__comment {
  display: flex;
  align-items: center;
}

.small-ratings {
  margin-right: 10px;
}
.small-ratings i {
  color: #cecece;
}
.rating-color {
  color: #fbc634 !important;
}

.product-detail__color-switch {
  margin-top: 10px;
  display: flex;
}

.color-item, .size-item {
  padding: 5px 0;
}

.color-item input[type='radio'],
.size-item input[type='radio'] {
  display: none;
}
.color-item input[type='radio'] + label:hover,
.color-item input:checked + label,
.color-item input[checked='true'] + label {
  transform: scale(1.125);
  border-color: #232c3f;
}
.imageSize:hover {
  transform: scale(1.125);
  border-color: #232c3f;
}
.active {
  transform: scale(1.125);
  border-color: #232c3f;
}
.image-circle {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin-right: 10px;
  text-align: center;
  padding: 6px;
  font-size: 0;
  width: 50px;
  height: 50px;
}
.color-circle {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin-right: 10px;
  text-align: center;
  padding: 6px;
  font-size: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.checked-color {
  font-weight: 400;
}

.product-detail__size-switch {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.size-check {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin-right: 10px;
  min-width: 50px;
  text-align: center;
  padding: 6px;
}
.size-item input[type='radio'] + label:hover,
.size-item input:checked + label {
  background-color: #000;
  color: #fff;
}
.checked-size {
  font-weight: 400;
}

.quantity-input-group {
  display: flex;
  margin-top: 10px;
}
.input-group-btn {
  display: inline-block;
  background-color: #ddd;
  color: #000;
  width: 52px;
  height: 48px;
  border-radius: 0;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.quantity-input-group input {
  margin: 0;
  width: 100px;
  text-align: center;
}

.product-detail__actions {
  margin-top: 34px;
}
.product-detail__add-to-cart,
.product-detail__buy {
  display: block;
  cursor: pointer;
  border-radius: 3px;
  transition: border-color 0.2s ease-out,
    box-shadow 225ms cubic-bezier(0.5, 0, 0.5, 1), color 0.2s ease-out;
  font-family: Slate, sans-serif;
  font-style: normal;
  font-weight: 200;
  letter-spacing: 0.1em;
  font-size: 0.8125rem;
  padding: 1.125rem 2rem;
  line-height: 1.4;
  color: #fff;
  background-color: #232c3f;
  border: 1px solid #232c3f;
  box-shadow: inset 0 0 #29344a;
  display: block;
  width: 100%;
  margin-bottom: 1.125rem;
}
.product-detail__buy {
  background-color: #2f7e6d;
  border-color: #2f7e6d;
}
.product-detail__add-to-cart:hover:not(:disabled) {
  color: #fff;
  border: 1px solid #29344a;
  box-shadow: inset 0 -4rem #29344a;
}
.product-detail__add-to-cart:disabled {
  background: #58585a;
  cursor: not-allowed;
}
.product-detail__buy:disabled {
  background: #8db5ac;
  border-color: #8db5ac;
  cursor: not-allowed;
}
.product-detail__buy:hover:not(:disabled) {
  color: #fff;
  border: 1px solid #2f7e6dd4;
  box-shadow: inset 0 -4rem #19493fd4;
}
.article-page__share-text p {
  margin: 0 0 1.5rem;
}
.article-page__share {
  /* padding: 0 32px; */
  text-align: left;
}
.article-page__share-text {
  margin-bottom: 0.75rem;
}
.share-buttons {
  display: flex;
  margin-right: -0.5rem;
}

.share-buttons__twitter,
.share-buttons__facebook,
.share-buttons__pinterest {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  transition: border-color 0.2s ease-out,
    box-shadow 225ms cubic-bezier(0.5, 0, 0.5, 1), color 0.2s ease-out;
  font-family: Slate, sans-serif;
  font-style: normal;
  font-weight: 200;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;
  color: #131416;
  background-color: transparent;
  border: 1px solid rgba(19, 20, 22, 0.3);
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.share-buttons__twitter svg,
.share-buttons__facebook svg,
.share-buttons__pinterest svg {
  width: auto;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
