<template>
  <div id="app" data-server-rendered="true">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  mounted() {
    document.dispatchEvent(new Event('render-event'))
    // document.dispatchEvent(new Event("custom-render-trigger"));
  },
}
</script>
