import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/layout';
import Home from '@/views/Home';
import Checkout from '@/views/Checkout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
        meta: { keepAlive: true }
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('../views/About.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'faq',
        name: 'Faq',
        component: () => import('../views/footerPage/Faq'),
        meta: { keepAlive: true }
      },
      {
        path: 'shipping',
        name: 'Shipping',
        component: () => import('../views/footerPage/Shipping.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'returns',
        name: 'Returns',
        component: () => import('../views/footerPage/Returns.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/footerPage/PrivacyPolicy.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'terms-of-use',
        name: 'TermsOfUse',
        component: () => import('../views/footerPage/TermsOfUse.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'track-my-order',
        name: 'TrackMyOrder',
        component: () => import('../views/footerPage/TrackMyOrder.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'logistics-track',
        name: 'LogisticsTrack',
        component: () => import('../views/LogisticsTrack.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'about-us-more',
        name: 'About',
        component: () => import('../views/footerPage/AboutUsMore.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'blog',
        name: 'Blog',
        component: () => import('../views/Blog.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'blog/:id',
        name: 'BlogDetail',
        component: () => import('../views/BlogDetail.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'product-list',
        name: 'ProductList',
        component: () => import('../views/ProductList.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'product-detail',
        name: 'ProductDetail',
        component: () => import('../views/ProductDetail.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'search-result',
        name: 'SearchResult',
        component: () => import('../views/SearchResult.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'catalog-list',
        name: 'CatalogList',
        component: () => import('../views/CatalogList.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('../views/Register.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import('../views/Account.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'order-detail/:id',
        name: 'OrderDetail',
        component: () => import('../views/OrderDetail.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'reset-password1',
        name: 'ResetPassword1',
        component: () => import('../views/ResetPassword1.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'reset-password2',
        name: 'ResetPassword2',
        component: () => import('../views/ResetPassword2.vue'),
        meta: { keepAlive: true }
      },
      {
        path: 'join-back',
        name: 'JoinBack',
        component: () => import('../views/JoinBack.vue')
      },
    ]
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/payment-finish',
    name: 'PaymentFinish',
    component: () => import('../views/PaymentFinish.vue'),
    meta: { keepAlive: true }
  },
  {
    path: '/payment-success',
    name: 'PaymentSuccess',
    component: () => import('../views/PaymentSuccess'),
    meta: { keepAlive: true }
  },
  {
    path: '/payment-success2',
    name: 'PaymentSuccess2',
    component: () => import('../views/PaymentSuccess2'),
    meta: { keepAlive: true }
  },
  {
    path: '/ubsubscribe',
    name: 'Ubsubscribe',
    component: () => import('../views/ubsubscribe'),
    // meta:{ keepAlive:true }
  },
  {
    path: '/payment-failure',
    name: 'PaymentFailure',
    component: () => import('../views/PaymentFailure'),
    meta: { keepAlive: true }
  },
  {
    path: '/payment-failure2',
    name: 'PaymentFailure2',
    component: () => import('../views/PaymentFailure2'),
    meta: { keepAlive: true }
  },
  {
    path: '/payment-cancel',
    name: 'PaymentCancel',
    component: () => import('../views/PaymentCancel'),
    meta: { keepAlive: true }
  },
  {
    path: '/email-cancel',
    name: 'EmailCancel',
    component: () => import('../views/EmailCancel')
  },
  {
    path: '/coupon',
    name: 'Coupon',
    component: () => import('../views/Coupon.vue'),
    // meta:{ keepAlive:true }
  },
  {
    path: '/redirect',
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index'),
        meta: { keepAlive: true }
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

const NEED_LOGIN_ROUTE_LIST = [] // 'Account'

router.beforeEach(async (to, from, next) => {
  console.log(to.name)
  console.log(from.name)

  if (from.name == 'ProductList' && to.name == 'ProductDetail') {
    sessionStorage.setItem('scrollTop', document.querySelector("html").scrollTop)
    to.meta.keepAlive = true
  } else if (from.name == 'Blog' && to.name == 'BlogDetail') {
    sessionStorage.setItem('scrollTop', document.querySelector("html").scrollTop)
    // to.meta.keepAlive = true
  } else if (from.name == 'SearchResult' && to.name == 'ProductDetail') {
    sessionStorage.setItem('scrollTop', document.querySelector("html").scrollTop)
  }
  next(0);
  if (to.name === 'Login') {
    next();
  } else if (NEED_LOGIN_ROUTE_LIST.includes(to.name)) {
    // 验证用户登录
    const token = localStorage.getItem('satoken') || sessionStorage.getItem('satoken');
    if (token) {
      next();
    } else {
      console.warn("请先登录");
      next({ path: `/login?redirect=${to.path}` });
    }
  } else {
    console.log(`游客访问`)
    next()
  }
})

export default router
