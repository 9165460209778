/* eslint-disable no-param-reassign */
export default {
  namespaced: true,
  state: {
    userInfo: null,
    userId: null,
    whatsapp: null,
  },
  mutations: {
    SET_USER_INFO({ state }, userInfo) {
      state.userInfo = userInfo
    },
    SET_WHATS_APP(state, whatsapp) {
      state.whatsapp = whatsapp
    }
  },
  actions: {
    setUserInfo({ commit }, userInfo) {
      commit('SET_USER_INFO', userInfo);
    },
    setWhatsApp({ commit }, whatsapp) {
      commit('SET_WHATS_APP', whatsapp);
    }
  }
};
