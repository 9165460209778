<template>
  <section class="main__wrapper join" style="background:#f1f1f1;margin-top: 0 !important;">
    <div class="div-default-mt" style="padding-top:10px;margin-bottom:0;margin-top: 0 !important;">
        <h2>Join our newsletter, as well as access to new releases.</h2>
        <div class="join-form row">
            <input type="text" placeholder="Email address" v-model="email">
            <button type="submit" class="btn btn-primary" @click="subscribe()">SUBSCRIBE</button>
        </div>
    </div>
  </section>
</template>
<script>
import service from '@/utils/request';
export default {
  name: 'Join',
  data() {
    return {
      email: ''
    }
  },
  methods: {
    subscribe() {
      if (!this.email) {
        // this.$message.warning('Please input an email!');
          this.$notify({
              title: 'Tip',
              message: 'Please input an email!',
              type: 'warning'
          });
        return;
      }
      this.$router.push({
        path: '/join-back',
        query: {
          email: this.email
        }
      })

    }
  }
}
</script>
