<template>
  <section class="shopify-section">
    <div class="v-stack gap-8">
      <div class="text-with-icons" >
        <div class="v-stack gap-6 float" style="float: left;">
          <div>
            <svg aria-hidden="true" focusable="false" fill="none" stroke-width="1.2" width="42" class="hidden sm:block icon icon-picto-delivery-truck" viewBox="0 0 24 24">
              <path d="M23.25 13.5V6a1.5 1.5 0 0 0-1.5-1.5h-12A1.5 1.5 0 0 0 8.25 6v6m0 0V6h-3a4.5 4.5 0 0 0-4.5 4.5v6a1.5 1.5 0 0 0 1.5 1.5H3" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M.75 12h3a1.5 1.5 0 0 0 1.5-1.5V6" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
              <path clip-rule="evenodd" d="M7.5 19.5a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Zm12 0a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M12 18h3" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
          <div class="v-stack gap-2 text-center">
            <p class="base-title h6 text-center">Free Shipping</p>
            <div class="prose">
              <p>No minimum spend</p>
            </div>
          </div>
        </div>
        <div class="v-stack gap-6 justify-items-center float"  style="float: left;">
          <div>
            <svg aria-hidden="true" focusable="false" fill="none" stroke-width="1.2" width="42" class="hidden sm:block icon icon-picto-return" viewBox="0 0 24 24">
              <path d="m1.25 15.08 2.207-3.384 3.385 2.206" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M13.13 2.5a9.525 9.525 0 1 1 0 19.049 9.68 9.68 0 0 1-9.673-9.853" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
          <div class="v-stack gap-2 text-center">
            <p class="base-title h6 text-center">90 DAY MONEY BACK PROMISE</p>
            <div class="prose">
              <p>
                On All Toys
              </p>
            </div>
          </div>
        </div>
        <div class="v-stack gap-6 justify-items-center float"  style="float: left;">
          <div>
            <svg aria-hidden="true" focusable="false" fill="none" stroke-width="1.2" width="42" class="hidden sm:block icon icon-picto-customer-support" viewBox="0 0 24 24">
              <path d="M12.75 15.75h3v4.5l4.5-4.5h1.494c.832 0 1.506-.674 1.506-1.506V2.25a1.5 1.5 0 0 0-1.5-1.5h-12a1.5 1.5 0 0 0-1.5 1.5v4.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M19.875 7.875a.375.375 0 1 0 0 .75.375.375 0 0 0 0-.75m-7.5 0a.375.375 0 1 0 0 .75.375.375 0 0 0 0-.75m3.75 0a.375.375 0 1 0 0 .75.375.375 0 0 0 0-.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
              <path clip-rule="evenodd" d="M6.75 16.5a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M12.75 23.25a6.054 6.054 0 0 0-12 0" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
          <div class="v-stack gap-2 text-center">
            <p class="base-title h6 text-center">First Rate Service</p>
            <div class="prose">
              <p>
                <router-link tag="a" to="/contact" class="link">Contact our Support Team</router-link>
              </p>
            </div>
          </div>
        </div>
        <div class="v-stack gap-6 justify-items-center float"  style="float: left;">
          <div>
            <svg aria-hidden="true" focusable="false" fill="none" stroke-width="1.2" width="42" class="hidden sm:block icon icon-picto-secure-profile" viewBox="0 0 24 24">
              <path clip-rule="evenodd" d="M23.25 9.937A13.5 13.5 0 0 1 12 23.25 13.5 13.5 0 0 1 .75 9.937V2.25a1.5 1.5 0 0 1 1.5-1.5h19.5a1.5 1.5 0 0 1 1.5 1.5v7.687Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
              <path clip-rule="evenodd" d="M12 12a3.375 3.375 0 1 0 0-6.75A3.375 3.375 0 0 0 12 12Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M17.387 16.75a6.032 6.032 0 0 0-10.774 0" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
          <div class="v-stack gap-2 text-center float" style="float: left;">
            <p class="base-title h6 text-center">Secure payments</p>
            <div class="prose">
              <p>Trustworthy, Secure, Reliable</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tight',
  data() {
    return {}
  },
  created() {
  },
  methods: {}
}
</script>

<style scoped>
svg{
  color:#cd0953
}
.shopify-section {
  /* max-width: 1080px; */
  margin: 0 auto;
  margin-top: 0 !important;
  background: #f1f1f1 !important;
  padding: 2.5rem 1rem 2.5rem 1rem;
  clear: both;
  border-block-start-width: 1px;
  /*background-color: #EAE6DD;*/
  /*border-bottom: 1px solid #D9D9D9;*/
}

@media screen and (min-width: 576px) {
  /* .float{
    width:285px !important;
  } */
}

.base-title{
  font-size: 14px !important;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-8 {
  gap: 2rem;
}
.v-stack {
  grid-template-columns: minmax(0,1fr);
  align-content: start;
  display: grid;
}

.text-with-icons {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
}
@media screen and (min-width: 700px) {
  .text-with-icons {
    grid-column-gap: 3rem;
    grid-row-gap: 2.5rem;
  }
}
@media screen and (min-width: 1000px) {
  .text-with-icons {
    grid-column-gap: 5rem;
  }
}
@media screen and (min-width: 1000px) {
  .text-with-icons:not(:has(>:nth-child(5))) {
    grid-column-gap: 4rem;
    grid-column-gap: 6.75rem;
  }
}
.justify-items-center {
  justify-items: safe center;
}
.prose {
  overflow-wrap: anywhere;
}
.h6 {
  font-size: 12px;
}

.link {
  text-underline: none;
  background: linear-gradient(to right, currentColor, currentColor) 0 min(100%, 1.35em) / 100% 1px no-repeat;
  transition: background-size .3s ease-in-out;
}
@media screen and (pointer: fine) {
  .link:hover {
    background-size: 0 1px;
  }
}
</style>
