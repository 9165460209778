<template>
  <main>
    <div class="checkout-page" v-loading="createOrderLoading">
      <div class="left-grid">
        <div>
          <div class="content">
            
            <div class="breadcrumbs" data-breadcrumbs="">
              <!--<a href="./index.html" class="breadcrumbs__link">Cart</a>-->
              <!--<span class="breadcrumbs__separator">/</span>-->
              <!--<a href="./category.html?type=category" class="breadcrumbs__link">Information</a>-->
              <!--<span class="breadcrumbs__separator">/</span>-->
              <!--<span class="breadcrumbs__current">Shipping</span>-->
              <!--<span class="breadcrumbs__separator">/</span>-->
              <!--<span class="breadcrumbs__current">Payment</span>-->
              
              <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }"
                  >Home</el-breadcrumb-item
                >
                <el-breadcrumb-item>Payment</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div v-show="currentTab === 'step1'">
              <el-form
                ref="step1Form"
                :model="step1Form"
                :rules="step1FormRules"
                label-position="top"
              >
                <div class="contact__wrapper">
                  <h4 class="text-start">Contact</h4>
                  <el-form-item label="Email" prop="email">
                    <el-input type="email" v-model="step1Form.email"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-checkbox
                      v-model="step1Form.isSubscriber"
                      :true-label="1"
                      :false-label="0"
                      >Email me with news and offers
                    </el-checkbox>
                  </el-form-item>
                </div>
                <div class="ship-address__wrapper section__wrapper">
                  <h4 class="text-start">Shipping address</h4>
                  <el-form-item label="Use Address" label-position="top">
                    <el-select
                      v-model="step1Form.addressType"
                      placeholder=""
                      style="width: 100%"
                      @change="handleAddressChange"
                    >
                      <el-option
                        v-for="item in addressList"
                        :key="item.id"
                        :value="item.id"
                        :label="item.street"
                      >
                        <span>Use {{ item.street }}</span>
                      </el-option>
                      <el-option value="-1" label="Add a new address"
                        >Add a new address</el-option
                      >
                    </el-select>
                  </el-form-item>
                  <address-form
                    :enableOperation="false"
                    ref="addressForm"
                    :edit-form="addressForm"
                    @selectName="selectName"
                  ></address-form>
                </div>
                <div class="ship-method__wrapper section__wrapper">
                  <h4 class="text-start">Shipping method</h4>
                  <div>
                    <div class="radio-item">
                      <el-radio
                        v-model="step1Form.shipMethod"
                        label="1"
                        style="display: flex; align-items: center"
                      >
                        <p
                          style="margin-left: 10px; flex: 1"
                          class="d-flex justify-content-between"
                        >
                          <span
                            >Express Courier (Air) / 7-15 business days</span
                          >
                          <!-- <span>$0.00</span> -->
                        </p>
                      </el-radio>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-5 pb-2 mb-5">
                  <button
                    type="button"
                    class="continue-button"
                    @click="goStep2()"
                  >
                    continue to payment
                  </button>
                </div>
              </el-form>
            </div>
            <div v-show="currentTab === 'step2'">
              <h4 class="title-checkout">Please Confirm Your Order Details</h4>
              <div class="section__wrapper">
                <div
                  class="section-box"
                  style="padding-left: 12px; padding-right: 12px"
                >
                  <el-row>
                    <el-col :span="4">Contact</el-col>
                    <el-col :span="16">{{ step1Form.email }}</el-col>
                    <el-col :span="4">
                      <span
                        class="text-decoration-underline text-pointer"
                        @click="backStep1()"
                        >Change</span
                      >
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">Ship to</el-col>
                    <el-col :span="16" id="shippingAddress">{{
                      joinAddressFields(this.addressForm)
                    }}</el-col>
                    <el-col :span="4">
                      <span
                        class="text-decoration-underline text-pointer"
                        @click="backStep1()"
                        >Change</span
                      >
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="4">Method</el-col>
                    <el-col :span="16"
                      >Express Courier (Air) / 7-15 business days</el-col
                    >
                    <el-col :span="4">
                      <span
                        class="text-decoration-underline text-pointer"
                        @click="backStep1()"
                        >Change</span
                      >
                    </el-col>
                  </el-row>
                </div>
              </div>
              <el-form
                :model="step2Form"
                ref="step2Form"
                :rules="step2FormRules"
              >
                <div class="paytype__wrapper section__wrapper">
                  <h4 class="text-start mb-0">Payment</h4>
                  <p class="mb-2">All transactions are safe and encrypted.</p>
                  <div class="radio-box pay-type">
                    <div class="radio-item" v-if="payList.includes('PayPal')">
                      <el-radio
                        v-model="step2Form.payType"
                        label="PayPal"
                        style="display: flex; align-items: center"
                      >
                        <div class="flex-space-between-center ms-2">
                          <div>PayPal</div>
                          <div>
                            <img
                              :src="require('@/assets/icon/PAYPAL.svg')"
                              alt="PAYPAL"
                              width="70px"
                            />
                          </div>
                        </div>
                      </el-radio>
                    </div>
                    <div
                      class="radio-item"
                      v-if="payList.includes('CreditCard')"
                    >
                      <el-radio
                        v-model="step2Form.payType"
                        label="CreditCard"
                        style="display: flex; align-items: center"
                      >
                        <div class="flex-space-between-center ms-2">
                          <div>Credit card</div>
                          <div>
                            <img
                              :src="require('@/assets/icon/VISA.svg')"
                              alt="VISA"
                              width="40px"
                            />
                            <img
                              :src="require('@/assets/icon/MASTERCARD.svg')"
                              alt="MASTERCARD"
                              width="40px"
                            />
                            <img
                              :src="require('@/assets/icon/JCB.svg')"
                              alt="JCB"
                              width="40px"
                            />
                            <!-- <img
                              :src="require('@/assets/icon/DISCOVER.svg')"
                              alt="DISCOVER"
                              width="40px"
                            />
                            <img
                              :src="require('@/assets/icon/DINERS_CLUB.svg')"
                              alt="DINERS_CLUB"
                              width="40px"
                            /> -->
                          </div>
                        </div>
                      </el-radio>
                      <div
                        class="radio-content"
                        v-if="step2Form.payType === 'CreditCard'"
                      >
                        <el-row :gutter="20" v-if="oftenPayList.length > 0">
                          <el-col :span="24">
                            <el-select
                              v-model="oftenValue"
                              placeholder="请选择"
                              style="width: 100%"
                            >
                              <el-option
                                v-for="item in oftenPayList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </el-col>
                        </el-row>
                        <el-row
                          style="margin-top: 20px"
                          :gutter="20"
                          v-if="
                            oftenValue == 'Use new card' ||
                            oftenPayList.length == 0
                          "
                        >
                          <el-col :span="24">
                            <el-form-item prop="cardNumber">
                              <el-input
                                maxlength="19"
                                v-model="step2Form.cardNumber"
                                placeholder="Card number"
                                @input="changeValue"
                              ></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24" :sm="12">
                            <el-form-item prop="month">
                              <el-date-picker
                                popper-class="card-month"
                                v-model="step2Form.month"
                                type="month"
                                placeholder="Month"
                                format="MM"
                                value-format="MM"
                                @change="changeMonth"
                              >
                              </el-date-picker>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24" :sm="12">
                            <el-form-item prop="year">
                              <!--:picker-options="pickerOptions"-->
                              <el-date-picker
                                v-model="step2Form.year"
                                type="year"
                                placeholder="Year"
                                value-format="yyyy"
                                @change="changeYear"
                              >
                              </el-date-picker>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24" :sm="12">
                            <el-form-item prop="cvv">
                              <el-input
                                maxlength="4"
                                v-model="step2Form.cvv"
                                placeholder="CVV"
                              ></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24" :sm="12">
                            <p class="card-msg-place">
                              3 or 4 digits usually found on the signature strip
                            </p>
                          </el-col>
                          <el-col :span="24" v-if="isToken">
                            <el-form-item prop="checked">
                              <el-checkbox
                                class="card-msg-place"
                                v-model="step2Form.checked"
                                >Save for next time</el-checkbox
                              >
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="isShowBillingAddress" class="section__wrapper">
                  <h4 class="text-start mb-0">Billing address</h4>
                  <p class="mb-2">
                    Select the address that matches your card or payment method.
                  </p>
                  <div class="radio-box">
                    <div class="radio-item">
                      <el-radio v-model="step2Form.billingAddressType" label="1"
                        >Same as shipping address</el-radio
                      >
                    </div>
                    <div class="radio-item">
                      <el-radio v-model="step2Form.billingAddressType" label="2"
                        >Use a different billing address
                      </el-radio>
                      <div
                        v-show="step2Form.billingAddressType === '2'"
                        class="radio-content"
                        style="padding-top: 20px"
                      >
                        <el-form-item label="Use Address" label-position="top">
                          <el-select
                            v-model="step2Form.addressType"
                            placeholder=""
                            style="width: 100%"
                            @change="handleBillingAddressChange"
                          >
                            <el-option
                              v-for="item in addressList"
                              :key="item.id"
                              :value="item.id"
                              :label="item.street"
                            >
                              <span
                                >Use
                                {{ item.firstName + ' ' + item.lastName }}
                                address</span
                              >
                            </el-option>
                            <el-option value="-1" label="Add a new address"
                              >Add a new address</el-option
                            >
                          </el-select>
                        </el-form-item>
                        <address-form
                          :enableOperation="false"
                          ref="billingAddressForm"
                          :edit-form="billingAddressForm"
                          @selectName="selectName"
                        ></address-form>
                      </div>
                    </div>
                  </div>
                </div>
              </el-form>
              <div class="text-end mt-3 pb-2 mb-5">
                <button
                  type="button"
                  class="continue-button"
                  @click="createOrder()"
                >
                  Pay now
                </button>
              </div>
            </div>
          </div>
          <div class="left-footer"></div>
        </div>
      </div>
      <aside class="right-grid">
        <div>
          <div class="logo text-center" style="font-size: 20px;">RiaJoy</div>
          <div class="product-list">
            <div class="product-item" v-for="item in cartList" :key="item.id">
              <div class="product-img">
                <el-badge
                  :value="item.productCount"
                  :max="99"
                  class="item"
                  type="danger"
                >
                  <img style="width: 62px" :src="item.productCoverImg" alt="" />
                </el-badge>
              </div>
              <div class="product-name">
                {{ item.productName }}
                <div
                  class="cart-item-attr"
                  :style="{ color: item.skuNameColor || '#000' }"
                >
                  {{ item.skuName || 'Color' }}
                  <!-- {{ `${item.skuSize ? `/${item.skuSize}` : ''}` }} -->
                </div>
              </div>
              <div class="product-amount" style="font-size:18px;color:#de6d5c;">${{ item.productPrice*item.productCount }}</div>
            </div>
          </div>
          <div>
            <label>Gift card or discount code<b>{{ hasCoupon ? `(${hasCoupon} coupons available) ` : `` }}</b></label>
            <div class="discount__wrapper d-flex justify-content-between mb-4">
              <el-select
                class="discount-select"
                style="width: 100%"
                v-model="step2Form.couponCode"
                placeholder=""
              >
                <el-option v-if="availableCouponList.length === 0" value=""
                  >No Available Coupon</el-option
                >
                <el-option
                  v-for="item in availableCouponList"
                  :key="item.code"
                  :value="item.code"
                  :label="item.showDiscount"
                >
                  <span style="float: left">{{ item.showDiscount }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.name
                  }}</span>
                </el-option>
              </el-select>
              <button
                type="button"
                class="discount-button"
                @click="applyCoupon()"
              >
                Apply
              </button>
            </div>
          </div>
          <div class="pay-amount-list">
            <div class="d-flex justify-content-between mb-2">
              <p style="font-size:18px;">Subtotal</p>
              <p style="font-size:18px;color:#de6d5c;">
                ${{ cartTotalPrice }}
              </p>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <p style="font-size:18px;">Shipping</p>
              <p style="font-size:18px;color:#de6d5c;">${{ Shipping }}</p>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <p style="font-size:18px;">Shipping Discount</p>
              <p style="font-size:18px;color:#de6d5c;">-${{ ShippingDiscount }}</p>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <p style="font-size:18px;">Order Discount</p>
              <p style="font-size:18px;color:#de6d5c;">-${{ totalDiscount }}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="fs-5"><strong>Total</strong></p>
              <p><strong style="font-size:20px;color:#de6d5c;">USD ${{ allTotalPrice }}</strong></p>
            </div>
          </div>
        </div>
      </aside>

      <el-dialog
        title="Payment"
        :visible.sync="paymentDialog"
        append-to-body
        :close-on-click-modal="false"
        :fullscreen="paymentFullscreen"
        @close="closePayment"
        class="payment"
      >
        <iframe
          :src="paymentUrl"
          frameborder="0"
          width="100%"
          height="100%"
          allowfullscreen
          style="min-height: 30rem"
        ></iframe>
      </el-dialog>
    </div>
  </main>
</template>
<script>
import service from '@/utils/request'
import AddressForm from '@/components/AddressForm'
import { detectDeviceType } from '@/utils/determine'
import { AES } from '@/utils/util'
export default {
  name: 'Checkout',
  components: { AddressForm },
  data() {
    var checkEmail = (rule, value, callback) => {
      const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regEmail.test(value)) {
        return callback();
      }
      callback(new Error("Please enter the correct email format"));
    };
    const creditCardValidate = (rule, value, callback) => {
      if (this.step2Form.payType === 'CreditCard' && !value) {
        callback(new Error(rule.message))
      } else {
        callback()
      }
    }
    const checkAgeCardValidate = (rule, value, callback) => {
      if (this.step2Form.payType === 'CreditCard' && !value) {
        callback(new Error(rule.message))
      } else {
        const regPos = /^[0-9]+.?[0-9]*/
        if (!regPos.test(value)) {
          callback(new Error('Please enter a numerical'))
        } else {
          callback()
        }
      }
    }
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        },
      },
      createOrderLoading: false,
      isQuickOrder: false,
      currentTab: 'step1',
      Shipping: 0,
      ShippingDiscount: 0,
      totalDiscount: 0,
      step1Form: {
        email: '',
        isSubscriber: 0,
        shipMethod: '1',
      },
      step1FormRules: {
        email: [
          { required: true, message: "Email is required", trigger: "blur" },
          { validator: checkEmail, trigger: "blur" },
        ],
      },
      step2Form: {
        billingAddressType: '',
        payType: 'PayPal',
        cardNumber: '',
        year: '',
        month: '',
        cvv: null,
        checked: false,
        addressType: '',
        couponCode: '',
      },
      step2FormRules: {
        cardNumber: [
          { validator: checkAgeCardValidate, trigger: 'blur', message: 'Card Number is Required' },
        ],
        year: [
          { validator: creditCardValidate, trigger: 'blur', message: 'Year is Required' },
        ],
        month: [
          { validator: creditCardValidate, trigger: 'blur', message: 'Month is Required' },
        ],
        cvv: [
          { validator: checkAgeCardValidate, trigger: 'blur', message: 'cvv is Required' },
        ],
      },
      isToken: '',
      oftenValue: '',
      oftenPayList: [],
      addressList: [],
      cartTotalPrice: '',
      singleProductPrice: '',
      cartList: [],
      addressForm: {},
      billingAddressForm: null,
      payInterval: null,
      payList: [],
      hasCoupon: 0,
      availableCouponList: [],

      paymentDialog: false,
      paymentFullscreen: false,
      paymentUrl: '',
    }
  },
  computed: {
    isShowBillingAddress() {
      /**issue:下单时候因为默认是paypal付款，不需要账单地址可以不显示，且需要不选中。只有选择信用卡付款时候才显示账单地址选择，且默认选中第一项。 */
      this.step2Form.billingAddressType =
        this.step2Form.payType !== 'PayPal' ? '1' : ''
      return this.step2Form.payType !== 'PayPal'
    },
    allTotalPrice() {
      return Math.max(this.roundToDecimalPlace(parseFloat(this.cartTotalPrice) + parseFloat(this.Shipping)
        - parseFloat(this.ShippingDiscount) - parseFloat(this.totalDiscount), 2), 0.1);
    },
  },
  async created() {
    const { productId, sellingPrice, productCount, imagesFlag } =
      this.$route.query
    //console.log(productId)
    if (productId) {
      this.isQuickOrder = true
      this.singleProductPrice = sellingPrice || ''
      this.cartTotalPrice = parseFloat(sellingPrice) * parseInt(productCount)
      this.getProductInfo(productId, imagesFlag)
      this.shippingFee(productId, productCount)
    } else {
      this.isQuickOrder = false
      await this.getCartList()
    }
    //console.log(456)
    const token =
      localStorage.getItem('satoken') || sessionStorage.getItem('satoken')
    this.isToken = token
    if (token) {
      this.getBaseInfo()
      this.getAddressList()
      this.getCouponNum(productId, sellingPrice, productCount)
    }
  },
  beforeDestroy() {
    if (this.payInterval) {
      clearInterval(this.payInterval)
      this.payInterval = null
    }
  },
  methods: {
    getCouponNum(productId, price, productCount) {
      service({
        url: productId
          ? `/coupon/canUseNum?productId=${productId}&price=${price}&num=${productCount}`
          : `/coupon/canUseNum`,
        method: 'get',
      }).then((res) => {
        if (res.code === 200 && res.data.num) {
          this.hasCoupon = res.data.num || 0
          if (this.hasCoupon) {
            this.getAvailableCouponList(productId, price, productCount)
          }
        }
      })
    },
    getAvailableCouponList(productId, price, productCount) {
      service({
        url: productId
          ? `/coupon/notUses?productId=${productId}&price=${price}&num=${productCount}`
          : `/coupon/notUses`,
        method: 'get',
      }).then((res) => {
        this.availableCouponList = res.data || []
      })
    },
    applyCoupon() {
      if (!this.step2Form.couponCode) {
        // this.$message.warning('Please choose a coupon!');
        this.$notify({
          title: 'Tip',
          message: 'Please choose a coupon!',
          type: 'warning',
        })
        return
      }
      const {sellingPrice, productCount} = this.$route.query
      service({
        url: this.isQuickOrder
          ? `/order/coupon/${this.step2Form.couponCode}?productId=${this.$route.query.productId}&price=${sellingPrice}&count=${productCount}`
          : `/order/coupon/${this.step2Form.couponCode}`,
        method: 'get',
      }).then((res) => {
        if (res.code === 200) {
          this.cartTotalPrice = res.data.totalPrice
          this.totalDiscount = res.data.discount
        }
      })
    },
    changeValue(val) {
      this.$nextTick(() => {
        this.step2Form.cardNumber = val
          .replace(/\D/g, '')
          .replace(/....(?!$)/g, '$& ')
      })
    },
    getBaseInfo() {
      service({
        url: `/user/info`,
        method: 'get',
      }).then((res) => {
        if (res.code === 200) {
          this.step1Form.email = res.data.account
        }
      })
    },
    getAddressList() {
      service({
        url: `/user/addresses`,
        method: 'get',
      }).then((res) => {
        this.addressList = res.data || []
        if (res.data) {
          this.step1Form.addressType = res.data[0].id
          this.handleAddressChange()
        }
      })
    },
    selectName(val) {
      //const { productId, productCount } = this.$route.query
      //this.shippingFee(productId, productCount, val)
    },
    shippingFee(productId, productCount) {
      //console.log(productId)
      //console.log(this.cartList)
      //console.log(this.addressList)
      let data = {}
      //console.log('----------------------' + this.cartTotalPrice);
      if (productId) {
        data = {
          productIds: [productId],
          nums: [productCount],
          //country: val ? val : this.addressList[0].country,
          cartTotalPrice: this.cartTotalPrice,
        }
      } else {
        data = {
          productIds: this.cartList.map((el) => el.productId),
          nums: this.cartList.map((el) => el.productCount),
          //country: val ? val : this.addressList[0].country,
          cartTotalPrice: this.cartTotalPrice,
        }
      }
      service({
        url: `/order/shippingFee`,
        method: 'post',
        timeout: 180000,
        data: data,
      })
        .then((res) => {
          //console.log(res)
          this.Shipping = parseFloat(res.data.fee) || 0
          this.ShippingDiscount = parseFloat(res.data.discount) || 0
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getProductInfo(productId, imagesFlag) {
      service({
        url: `/product/${productId}`,
        method: 'get',
      }).then((res) => {
        //console.log('-----------' + JSON.stringify(this.$route.query));
        const { productCount, checkedAttr, sellingPrice } = this.$route.query
        let allKeyValue = JSON.parse(checkedAttr)
        // let allkey = Object.keys(allKeyValue)
        let allValue = Object.values(allKeyValue)
        let skuName = ''
        //console.log(allValue)
        if (allKeyValue.Color) {
          allValue = allValue.filter((el) => el != allKeyValue.Color)
          skuName =
            `Color${allValue.length > 0 ? ' / ' : ''}` + allValue.join(' / ')
        } else {
          skuName = allValue.join(' / ')
        }
        //console.log(checkedAttr)
        const { name, coverImg } = res.data
        this.cartList = [
          {
            productCount: productCount,
            productCoverImg: imagesFlag ? imagesFlag : coverImg,
            productName: name,
            productPrice: sellingPrice,
            skuNameColor: JSON.parse(checkedAttr).Color,
            skuName: skuName,
            // skuSize: JSON.parse(checkedAttr).Size,
          },
        ]
        //console.log(checkedAttr)
      })
    },
    getCartList() {
      return service({
        url: `/car/list`,
        method: 'get',
      }).then((res) => {
        //console.log(123)
        this.cartTotalPrice = res.data.totalPrice
        this.cartList = res.data.list
        this.Shipping = parseFloat(res.data.shippingFee) || 0
        this.ShippingDiscount = parseFloat(res.data.shippingDiscount) || 0
        //this.shippingFee()
      })
    },
    joinAddressFields(address) {
      if (!address || Object.keys(address).length === 0) {
        return ''
      }
      return `${address.street} ${address.houseNumber} ${address.city}, ${address.postCode}, ${address.province} ${address.country}`
    },
    handleAddressChange() {
      //console.log(this.step1Form.addressType)
      if (this.step1Form.addressType == -1) {
        this.addressForm = {}
      } else {
        //console.log(this.addressList)
        const addressForm = this.addressList.find(
          (item) => item.id === this.step1Form.addressType
        )
        this.addressForm = addressForm
      }
      //console.log(this.addressForm)
    },
    handleBillingAddressChange() {
      if (this.step2Form.addressType === -1) {
        this.billingAddressForm = {}
      } else {
        const addressForm = this.addressList.find(
          (item) => item.id === this.step2Form.addressType
        )
        this.billingAddressForm = addressForm
      }
    },
    goStep2() {
      this.$refs.step1Form.validate((valid) => {
        this.$refs.addressForm.validate().then((valid2) => {
          if (!valid || !valid2) {
            return
          }
          const { productId } = this.$route.query
          const productIds = !!productId ? [productId] : this.cartList.map(el => el.productId)
          const reqParams = productIds.join('&productId=')
          service({
            url: `/order/getPayments/?productId=${reqParams}`,
            method: 'get',
          }).then((res) => {
            this.oftenPayList = []
            console.log(res.data)
            if (res.data.ccSerial) {
              const { ccSerial } = res.data
              this.oftenPayList = [ccSerial].concat([
                {
                  name: 'Use new card',
                  value: 'Use new card',
                },
              ])
              console.log(this.oftenPayList)
              this.oftenValue = this.oftenPayList[0].value
            }
            this.payList = res.data.types.map((el) => el.value)
            if (detectDeviceType()) {
              window.scrollTo({
                top: 0,
                // behavior: 'smooth',
              })
            }
            this.currentTab = 'step2'
            // console.log(this.addressForm)
          })
        })
      })
    },
    backStep1() {
      this.currentTab = 'step1'
    },
    createOrder() {
      this.$refs.step2Form.validate(async (valid) => {
        let valid2 = false
        if (
          this.step2Form.payType.toLowerCase() === 'paypal' ||
          this.step2Form.billingAddressType === '1'
        ) {
          valid2 = true
        } else {
          valid2 = await this.$refs.billingAddressForm.validate()
        }
        if (!valid || !valid2) {
          return
        }
        let billAddress = {}
        const { productId, productCount, checkedAttr } = this.$route.query
        const { email, isSubscriber } = this.step1Form
        const {
          payType,
          couponCode,
          cardNumber,
          expMonth,
          expYear,
          cvv,
          checked,
        } = this.step2Form
        console.log(this.step2Form)
        const {
          firstName,
          lastName,
          countryCode,
          phone,
          country,
          province,
          city,
          street,
          houseNumber,
          postCode,
        } = this.addressForm
        if (this.step2Form.billingAddressType === '2') {
          billAddress = this.$refs.billingAddressForm.addressForm
        } else {
          billAddress = {
            firstName,
            lastName,
            countryCode,
            phone,
            country,
            province,
            city,
            street,
            houseNumber,
            postCode,
          }
        }
        const numberCard = cardNumber.replace(/\s*/g, '')
        const orderSource = detectDeviceType() ? 'APP' : 'PC'
        let aes = new AES()
        this.createOrderLoading = true
        if (this.isQuickOrder) {
          const attrList = Object.keys(JSON.parse(checkedAttr)).map((item) => {
            return {
              attrCode: item,
              attrValue: JSON.parse(checkedAttr)[item],
            }
          })
          service({
            url: `/order/quickPlace`,
            method: 'post',
            timeout: 180000, // 3m
            data: {
              productId,
              productCount,
              attrList,
              contact: email,
              shippingAddress: {
                firstName,
                lastName,
                countryCode,
                phone,
                country,
                province,
                city,
                street,
                houseNumber,
                postCode,
              },
              billingAddress: billAddress,
              payType,
              couponCode,
              orderSource,
              userMessage: '', // 暂无
              isSubscriber,
              cardNumber,
              expMonth: aes.Encrypt(expMonth),
              expYear: aes.Encrypt(expYear),
              cardNo: aes.Encrypt(numberCard),
              ccSerial:
                this.oftenValue == 'Use new card' ? '' : this.oftenValue,
              saveCard: checked ? 1 : 0,
              cvv: aes.Encrypt(cvv),
            },
          })
          .then((res) => {
            const { code, data } = res
            if (code == 200) {
              const { anonymousId, orderId, url } = data
              anonymousId ? localStorage.setItem('am', anonymousId) : ''
              orderId ? this.checkPayStatus(orderId) : ''
              this.openPayment(url)
            }
          })
          .catch((err) => {
            console.log(err)
          }).finally(() => {
            this.createOrderLoading = false
          })
        } else {
          service({
            url: `/order/placeOrder`,
            method: 'post',
            timeout: 180000, // 3m
            data: {
              contact: email,
              shippingAddress: {
                firstName,
                lastName,
                countryCode,
                phone,
                country,
                province,
                city,
                street,
                houseNumber,
                postCode,
              },
              billingAddress: billAddress,
              payType,
              couponCode,
              orderSource,
              userMessage: '', // 暂无
              isSubscriber,
              cardNumber,
              expMonth: aes.Encrypt(expMonth),
              expYear: aes.Encrypt(expYear),
              cvv: aes.Encrypt(cvv),
              cardNo: aes.Encrypt(numberCard),
              ccSerial:
                this.oftenValue == 'Use new card' ? '' : this.oftenValue,
              saveCard: checked ? 1 : 0,
            },
          })
          .then((res) => {
            const { code, data, msg } = res
            if (code == 200) {
              const { anonymousId, orderId, url } = data
              anonymousId ? localStorage.setItem('am', anonymousId) : ''
              orderId ? this.checkPayStatus(orderId) : ''
              this.openPayment(url)
            }
          })
          .catch((err) => {
            console.error(err)
          }).finally(() => {
            this.createOrderLoading = false
          })
        }
      })
    },
    openPayment(url) {
      if (!url) return

      window.open(url, '_self')
      // this.paymentFullscreen = document.body.clientWidth < 576
      // this.paymentUrl = url
      // this.paymentDialog = true
    },
    checkPayStatus(orderId) {
      // 查询支付结果
      this.payInterval = setInterval(() => {
        service({
          url: `/order/payResult/${orderId}`,
          method: 'get',
        }).then((res) => {
          if (res && res.code === 200 && res.data.payStatus != '0') {
            clearInterval(this.payInterval)
            this.payInterval = null
            this.createOrderLoading = false
            this.payStatusChange(res.data.payStatus)
          }
        })
      }, 3000)
    },
    payStatusChange(payStatus) {
      this.$router.push({
        name: 'PaymentFinish',
        params: {
          payStatus,
          totalPrice: this.isQuickOrder
            ? this.singleProductPrice
            : this.cartTotalPrice,
        },
      })
    },
    closePayment() {
      this.paymentUrl = ''
      this.paymentFullscreen = false
      this.paymentDialog = false
      this.createOrderLoading = false
    },
    changeMonth(val) {
      this.step2Form.expMonth = val
    },
    changeYear(val) {
      this.step2Form.expYear = val
    },
    // 四舍五入到指定的小数位数
    roundToDecimalPlace(number, decimalPlaces) {
      var factor = Math.pow(10, decimalPlaces);
      var tempNumber = number * factor;
      var roundedTempNumber = Math.round(tempNumber);
      return roundedTempNumber / factor;
    }
  },
}
</script>
<style lang="scss" scoped>
body {
  background-color: rgb(250, 250, 250);
}

main {
  margin: 0;
}

.checkout-page {
  display: grid;
  grid-template-areas:
    'header'
    'disclosure'
    'subheader'
    'main'
    'footer';
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr auto;
  min-height: 100vh;
  font-size: 14px;
}

@media (min-width: 1000px) {
  .checkout-page {
    grid-template-areas: 'header header' 'disclosure disclosure' 'subheader subheader' 'main secondary' 'footer footer';
    grid-template-columns: 58% 42%;
  }
}

@media (min-width: 1223px) {
  .checkout-page {
    grid-template-columns: calc(50% + 88px) 1fr;
  }
}

.left-grid {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
}

.left-grid > div {
  max-width: calc(554px + 2vw);
  padding-inline-end: 5.4vw;
  padding-inline-start: 5vw;
  padding-top: 1.6rem;
  text-align: left;
}

.breadcrumbs {
  margin-bottom: 26px;
}

.right-grid {
  background: rgb(239, 239, 239);
  min-height: 100vh;
  border-left: 0.8px solid rgb(207, 207, 207);
}

.right-grid > div {
  max-width: calc(438px + 5vw);
  padding-inline-end: 5.4vw;
  padding-inline-start: 2vw;
  padding: 33px;
  padding-top: 5.6rem;
  text-align: left;
}

h4 {
  margin-bottom: 14px;
}

label {
  cursor: pointer;
}

.section__wrapper {
  margin-top: 32px;
}

.radio-item {
  border: 1px solid rgb(218, 218, 218);
  padding: 15px;
}

.radio-item:not(:last-child) {
  border-bottom: none;
}

.radio-option > label {
  padding: 17px;
  border-bottom: 1px solid rgb(218, 218, 218);
}

.radio-content {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 15px;
}

.radio-content input,
.radio-content select {
  background: transparent !important;
  margin-bottom: 0px !important;
}

.logo {
  margin-bottom: 28px;
}

.product-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.product-img {
  position: relative;
  width: 64px;
  border: 1px solid rgb(235, 235, 235);
  margin-right: 14px;
}

.product-img img {
  width: 100%;
  border-radius: 4px;
}

.product-name {
  flex: 1;
  font-weight: bold;
  font-size: 16px;
}

.cart-item-attr {
  font-weight: 100 !important;
}

.product-amount {
  text-align: right;
  width: 90px;
}

::v-deep .el-select.discount-select input {
  background: rgba(0, 0, 0, 0) !important;
  border-color: rgb(207, 207, 207) !important;
  margin: 0 18px 0 0 !important;
}

.discount-button {
  background: rgb(230, 230, 230);
  border: 1px solid rgb(207, 207, 207);
  line-height: 21px;
  outline: none;
  cursor: pointer;
}

.section-box {
  border: 1px solid rgb(218, 218, 218);
  border-radius: 4px;
  padding: 10px 2px 2px 2px;
  margin-bottom: 10px;
}

.section-box input,
.section-box select {
  background: rgba(0, 0, 0, 0) !important;
  border: none !important;
  margin: 0 !important;
  padding: 8px 12px !important;
}

.section-box select {
  color: #131416 !important;
  font-family: Kanit, sans-serif;
  font-size: 16px;
}

.continue-button {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  transition: border-color 0.2s ease-out,
    box-shadow 225ms cubic-bezier(0.5, 0, 0.5, 1), color 0.2s ease-out;
  font-family: Slate, sans-serif;
  font-style: normal;
  font-weight: 200;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0.75rem 1.125rem;
  line-height: 1.4;
  color: #fff;
  background-color: #232c3f;
  border: 1px solid #232c3f;
  box-shadow: inset 0 0 #29344a;
}

.pay-type {
  ::v-deep .el-radio {
    margin: 0;
  }
  ::v-deep .el-radio__label {
    width: 100%;
  }
  img {
    object-fit: cover;
    margin-right: 5px;
  }
}

.payment {
  ::v-deep .el-dialog__body {
    height: calc(100% - 120px);
  }
}

.card-msg-place {
  color: #9e9e9e;
  line-height: 24px;
}
.title-checkout{
  font-family: Futura, sans-serif;
  font-size: 16px;
  margin:0 0 12px 0;
  font-weight: 600;
}
</style>
